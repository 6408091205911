<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard
              color="warning"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>CUUniversity</h2>
                <p>Your trusted training partner.</p>
              </CCardBody>
            </CCard>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit.prevent="login">
                  <h3 style="text-align: center">Welcome to CUUniversity</h3>
                  <p class="text-muted">Sign In to your account</p>
                  <p v-if="errormessage != ''" class="text-danger text-center">
                    {{ errormessage }}
                  </p>
                  <CInput
                    placeholder="Enter Email"
                    autocomplete="off"
                    :isValid="checkIfValid('email')"
                    :value.sync="$v.user.email.$model"
                    :maxlength="100"
                    invalidFeedback="Email is Required"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <CInput
                    placeholder="Enter Password"
                    autocomplete="curent-password"
                    :isValid="checkIfValid('password')"
                    :value.sync="$v.user.password.$model"
                    :maxlength="20"
                    invalidFeedback="Password is Required"
                    type="password"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <CRow>
                    <CCol class="text-left">
                      <CButton
                        type="submit"
                        size="lg"
                        block
                        color="warning"
                        class="px-4"
                        :disabled="submitted"
                        >Login</CButton
                      >
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faUserSecret);

export default {
  name: "Login",
  components: { FontAwesomeIcon },
  data() {
    return {
      user: {
        email: "",
        password: "",
        userType: "admin",
      },
      errormessage: "",
      submitted: false,
    };
  },
  validations: {
    user: {
      email: { required },
      password: { required },
    },
  },
  created() {
    document.title = this.$store.state.projecttitle + " - Login";
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.user[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    login() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.submitted = true;
      this.$store
        .dispatch("login", this.user)
        .then((response) => {
          console.log(response);
          if (response.data.statusCode == 200) {
            this.errormessage = "";
            this.$router.push({ name: "Dashboard" });
          } else {
            this.errormessage = response.data.message;
          }
          this.submitted = false;
        })
        .catch((errors) => {});
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/style";

.is-invalid .vs__search::placeholder,
.is-invalid .vs__dropdown-toggle,
.is-invalid .vs__dropdown-menu {
  border-color: #e55353;
}
</style>

